.accordion {
	&__heading {
		@include sans-serif-bold;
		padding: 1rem 0;
		border-bottom: 1px solid $gray-4;
		&__arrow {
			float: right;
			margin-top: .5rem;
			transition: transform .4s;
			path {
				fill: $turquoise;
			}
			.accordion__heading--is-open & {
				transform: rotate(180deg);
			}
		}
		&:hover {
			color: $turquoise;
			cursor: pointer;
		}
	}
}

@media only screen {
	.JS .accordion {
		&__content {
			display: none;
		}
	}
}
