.article__section--dark + .related-impact-entries {
  background-color: $gray-1;

  & + .related-impact-entries {
    background-color: white;
  }
}

.related-impact-entries {
  background-color: white;
	padding-bottom: 1rem;

  & + .related-impact-entries {
    background-color: $gray-1;
  }

	&__heading {
		@extend %gutters;
		@include h2;
		margin: 2rem 0 3rem;
		// color: #fff;
	}
}

@include breakpoint(large) {
  .related-impact-entries {
    padding-bottom: 3rem;

    &__heading {
      margin: 3rem 0 5rem;
    }
  }
}