.gradient-header {
	background: #000;
	background: linear-gradient(250deg, #4b287a, #f11782, #f39200, #00b1bb, #384aac);
	background-size: 800% 800%;
	animation: hero 30s linear infinite;
	&__text {
		@extend %gutters;
		@include h1;
		color: #fff;
		padding-top: 9rem;
		padding-bottom: 3rem;
		@include breakpoint(smallish) {
			padding-top: 5rem;
		}
		@include breakpoint(large) {
			padding-top: 9rem;
			padding-bottom: 5rem;
		}
		@include breakpoint(huge) {
			padding-top: 12rem;
		}
	}
}
@-webkit-keyframes hero {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}
@-moz-keyframes hero {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}
@keyframes hero {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}