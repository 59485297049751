.stats {
	margin-top: 2rem;
	margin-bottom: 2rem;
	@include clearfix;
	&__stat {
		@extend %gutters;
		margin-bottom: 1rem;
	}
	&__figure {
		font-size: 4rem;
		line-height: 1;
	}
	&__text {
		@include sans-serif;
		color: $gray-5;
	}
	@include breakpoint(smallish) {
		width: 66.666666%;
		margin-left: 33.333333%;
		
	}
	@include breakpoint(600px) {
		&__stat {
			width: 50%;
			float: left;
			&:nth-of-type(odd) {
				clear: left;
			}
		}
	}
	@include breakpoint(large) {
		width: 50%;
		&__figure {
			font-size: 5rem;
		}
	}
}