.work {
	background: $gray-1;
	padding-bottom: 3rem;
	&__header {
		@extend %gutters;
		&__heading {
			@include h2;
			margin: 2rem 0 3rem;
			&--category {
				@include h4;
				@include short-bottom-rule();
			}
		}
		&__category {
			@include h2;
			margin-bottom: 3rem;
			color: $gray-4;
		}
	}
	.select, &__show-all { // filter/show all button
		margin: 0 0 3rem $gutter-half-width-small;
		@include breakpoint(smallish) {
			margin: 0 0 3rem $gutter-half-width-smallish;
		}
		@include breakpoint(medium) {
			margin: 0 0 3rem $gutter-half-width-medium;
		}
	}
	&__show-all {
		display: inline-block;
		@include sans-serif-bold;
		color: $turquoise;
		&__svg {
			margin-right: .5rem;
			position: relative;
			top: .05rem;
			path {
				fill: $turquoise;
			}
		}
		&:hover {
			color: $dark-turquoise;
			path {
				fill: $dark-turquoise;
			}
		}
	}
	@include breakpoint(medium) {
		&__header__heading--category {
			padding-bottom: 1rem;
			margin-bottom: 1rem;
		}
	}
	@include breakpoint(large) {
		padding-bottom: 5rem;
		&__header {
			&__heading {
				margin-top: 3rem;
			}
		}
	}
}