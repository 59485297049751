.home-activity {
	background: $gray-1;
	&__heading {
		@include h2;
		@extend %gutters;
		margin: 2rem 0 3rem;
	}
	&__see-more {
		@include h2;
		text-align: center;
		margin-bottom: 3rem;
		&__link {
			color: $turquoise;
			@include no-link;
		}
	}
	@include breakpoint(large) {
		&__heading {
			margin: 3rem 0 5rem;
		}
		&__see-more {
			margin-bottom: 5rem;
		}
	}
}