.values {
	padding-bottom: 2rem;
	&__value {
		@extend %gutters;
		padding-top: 2rem;
		padding-bottom: 1rem;
	}
	&__img {
		width: 15rem;
		height: 15rem;
		display: block;
		margin: 0 auto 2rem;
	}
	&__heading {
		margin-bottom: 2rem;
	}
	&__value:nth-child(2) &__heading {
		color: $pink;
	}
	&__value:nth-child(3) &__heading {
		color: $brown;
	}
	&__value:nth-child(4) &__heading {
		color: $turquoise;
	}
	@include breakpoint(smallish) {
		&__value {
			width: 66.666666%;
			float: left;
			&:nth-child(even) {
				float: right;
			}
		}
	}
	@include breakpoint(medium) {
		&__img {
			width: 17rem;
			height: 17rem;
		}
	}
	@include breakpoint(large) {
		padding-bottom: 4rem;
		&__value {
			width: 100%;
			float: none;
			padding-top: 3rem;
			padding-bottom: 2rem;
			@include clearfix;
			display: flex;
			&:nth-child(even) {
				flex-direction: row-reverse;
			}
		}
		&__img {
			margin-bottom: 0;
			width: 26rem;
			height: 26rem;
		}
		&__text, &__img-wrap {
			width: 50%;
			float: right;
			.values__value:nth-child(even) & {
				float: left;
			}
		}
		&__text {
			@include box;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		&__heading {
			margin-bottom: 3rem;
		}
	}
	@include breakpoint(huge) {
		&__img {
			width: 30rem;
			height: 30rem;
		}
	}
}