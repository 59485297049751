/******************************************************************************************
HELPER MIXINS
******************************************************************************************/

@mixin box {
	box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; *behavior: url(/js/third-party/boxsizing.htc);	
}

@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	} 
	&:after {
		clear: both;
	}
	zoom: 1;
}

/******************************************************************************************
BREAKPOINTS
******************************************************************************************/

// based on combination of http://jakearchibald.github.io/sass-ie/ and http://css-tricks.com/media-queries-sass-3-2-and-codekit/

$fixed-width: 0 !default;
$old-ie: false !default;

@mixin breakpoint($point) {
	// If we're outputting for a fixed media query set...
	@if $old-ie {
		// ...and if we should apply these rules...
		@if $point == huge {
			@if $fixed-width >= $huge-screen { @content; }
		}
		@else if $point == large {
			@if $fixed-width >= $large-screen { @content; }
		}
		@else if $point == medium {
			@if $fixed-width >= $medium-screen { @content; }
		}
		@else if $point == smallish {
			@if $fixed-width >= $smallish-screen { @content; }
		}
		@else {
			@if $fixed-width >= $point { @content; }
		}
	}
	@else {
		// Otherwise, output it using a regular media query
		@if $point == huge {
			@media screen and (min-width: $huge-screen) { @content; }
		}
		@else if $point == large {
			@media screen and (min-width: $large-screen)	{ @content; }
		}
		@else if $point == medium {
			@media screen and (min-width: $medium-screen)	{ @content; }
		}
		@else if $point == smallish {
			@media screen and (min-width: $smallish-screen)	{ @content; }
		}
		@else {
			@media screen and (min-width: $point) { @content; }
		}
	}
}
@mixin below-breakpoint($point) {
	// If we're outputting for a fixed media query set...
	@if $old-ie {
		// ...and if we should apply these rules...
		@if $point == huge {
			@if $fixed-width < $huge-screen { @content; }
		}
		@else if $point == large {
			@if $fixed-width < $large-screen { @content; }
		}
		@else if $point == medium {
			@if $fixed-width < $medium-screen { @content; }
		}
		@else if $point == smallish {
			@if $fixed-width < $medium-screen { @content; }
		}
	}
	@else {
		// Otherwise, output it using a regular media query
		@if $point == huge {
			@media screen and (max-width: $huge-screen - 1) { @content; }
		}
		@else if $point == large {
			@media screen and (max-width: $large-screen - 1)	{ @content; }
		}
		@else if $point == medium {
			@media screen and (max-width: $medium-screen - 1)	{ @content; }
		}
		@else if $point == smallish {
			@media screen and (max-width: $smallish-screen - 1)	{ @content; }
		}
	}
}
@mixin old-ie {
	// Only use this content if we're dealing with old IE
	@if $old-ie {
		@content;
	}
}

@mixin button-reset() {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  text-align: inherit;
  line-height: normal;
  font-weight: inherit;
  background-color: transparent;
  transition: none;

  &:hover {
    background-color: transparent;
  }
}

/******************************************************************************************
RULES ETC
******************************************************************************************/

%top-rule-white {
	position: relative;
	&::before {
		content: "";
		display: block;
		border-top: 2px solid #fff;
		position: absolute;
		top: -1px;
		left: $gutter-half-width-small;
		right: $gutter-half-width-small;
		@include breakpoint(smallish) {
			left: $gutter-half-width-smallish;
			right: $gutter-half-width-smallish;
		}
		@include breakpoint(medium) {
			left: $gutter-half-width-medium;
			right: $gutter-half-width-medium;
		}
	}
}
%bottom-rule-white {
	position: relative;
	&::after {
		content: "";
		display: block;
		border-top: 2px solid #fff;
		position: absolute;
		bottom: -1px;
		left: $gutter-half-width-small;
		right: $gutter-half-width-small;
		@include breakpoint(smallish) {
			left: $gutter-half-width-smallish;
			right: $gutter-half-width-smallish;
		}
		@include breakpoint(medium) {
			left: $gutter-half-width-medium;
			right: $gutter-half-width-medium;
		}
	}
}
%top-rule-grey {
	@extend %top-rule-white;
	&::before {
		border-color: $gray-6;
	}
}
%thin-top-rule-grey-5 {
	@extend %top-rule-white;
	&::before {
		border-color: $gray-5;
		border-width: 1px;
	}
}
%thin-bottom-rule-grey-2 {
	@extend %bottom-rule-white;
	&::after {
		border-color: $gray-2;
		border-width: 1px;
	}
}


@mixin short-top-rule($color: $turquoise) {
	padding-top: .5rem;
	margin-top: .5rem;
	position: relative;
	&::before {
		content: "";
		display: block;
		border-top: 2px solid $color; // $turquoise default colour
		position: absolute;
		top: -1px;
		width: 4rem;
	}
}
@mixin short-bottom-rule($color: $turquoise) {
	padding-bottom: .5rem;
	margin-bottom: .5rem;
	position: relative;
	&::before {
		content: "";
		display: block;
		border-top: 2px solid $color; // $turquoise default colour
		position: absolute;
		bottom: -1px;
		width: 4rem;
	}
}

/******************************************************************************************

******************************************************************************************/

// SET HEIGHT EXPLICITLY FOR IOS, AS VH IS BUGGY IN SAFARI

// these are whatever is the deepest chrome for each device in Safari or Chrome
$iphone-chrome-height-portait: 108px;
$iphone-chrome-height-landscape: 43px;
$ipad-chrome-height-portait: 116px;
$ipad-chrome-height-landscape: 116px;

@mixin vh100-ios($selector:"height") {
	#{$selector}: 100vh;
	// ----------- iPhone 4 and 4S -----------
	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
		#{$selector}: 480px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
		#{$selector}: 320px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 5 and 5S -----------
	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
		#{$selector}: 568px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
		#{$selector}: 320px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 6 -----------
	@media only screen and (min-device-width: 375px) and (max-device-width: 667px)	and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) { 
		#{$selector}: 667px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 375px) and (max-device-width: 667px)	and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) { 
		#{$selector}: 375px - $iphone-chrome-height-landscape;
	}
	// ----------- iPhone 6+ -----------
	@media only screen and (min-device-width: 414px) and (max-device-width: 736px)	and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) { 
		#{$selector}: 736px - $iphone-chrome-height-portait;
	}
	@media only screen and (min-device-width: 414px) and (max-device-width: 736px)	and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
		#{$selector}: 414px - $iphone-chrome-height-landscape;
	}
	/* ----------- iPad mini, 1 and 2 ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
		#{$selector}: 1024px - $ipad-chrome-height-portait;
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		#{$selector}: 768px - $ipad-chrome-height-landscape;
	}
	/* ----------- iPad 3 and 4 ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
		#{$selector}: 1024px - $ipad-chrome-height-portait;
	}
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
		#{$selector}: 768px - $ipad-chrome-height-landscape;
	}
	// fallback for browsers that don't support vh units
	.no-cssvhunit & {
		#{$selector}: 800px;
	}
}