.client-logos {
	background: $gray-6;
	padding-top: 2rem;
	&__heading {
		color: #fff;
		margin-bottom: 2rem;
		@extend %gutters;
		max-width: 25rem;
	}
	&__list {
		@extend %gutters;
		padding-bottom: 1rem;
		@include clearfix;
	}
	&__li {
		width: 33.333333%;
		float: left;
		margin-bottom: 2rem;
		&:nth-child(3n+1) {
			clear: both;
		}
	}
	&__logo {
		display: block;
		margin: 0 auto;
		&:hover path {
			fill: #fff;
		}
	}
	&__full-list-wrap {
		padding-top: 1rem;
		padding-bottom: 3rem;
		@extend %gutters;
		@extend %thin-top-rule-grey-5;
	}
	&__full-list {
		padding: 0 0 2rem;
		&__item {
			@include sans-serif;
			padding-left: 1.5rem;
			text-indent: -1.5rem;
			color: $gray-2;
			display: inline-block; width: 100%; // this stops two-line items breaking across columns
		}
	}
	@include breakpoint(smallish) {
		&__heading {
			margin-bottom: 3rem;
		}
		&__li {
			width: 25%;
			&:nth-child(3n+1) {
				clear: none;
			}
			&:nth-child(4n+1) {
				clear: both;
			}
		}
		&__full-list-wrap {
			padding-top: 2rem;
		}
		&__full-list {
			clear: both;
			column-count: 2;
			column-gap: $gutter-half-width-small * 2;
		}
	}
	@include breakpoint(medium) {
		&__heading {
			width: 100%;
			max-width: 40rem;
		}
		&__logo {
			width: 11.2rem;
			height: 6rem;
		}
		&__full-list {
			column-gap: $gutter-half-width-medium * 2;
		}
	}
	@include breakpoint(large) {
		padding-top: 3rem;
		&__heading {
			margin-bottom: 5rem;
		}
		&__list {
			padding-bottom: 3rem;
		}
		&__li {
			width: 16.666666%;
			&:nth-child(4n+1) {
				clear: none;
			}
			&:nth-child(6n+1) {
				clear: both;
			}
		}
		&__full-list-wrap {
			padding-top: 3rem;
			padding-bottom: 5rem;
		}
	}
	@include breakpoint(huge) {
		&__logo {
			width: 12.26666rem;
			height: 8rem;
		}
		&__full-list {
			column-count: 3;
		}
	}
}