.filters {
	margin-bottom: 3rem;
	@extend %gutters;
	&__panel {
		background: $turquoise;
		@include clearfix;
		@extend %gutters;
		padding-bottom: 1rem;
		max-width: $max-screen-width - ($gutter-half-width-medium * 2);
		margin-left: auto;
		margin-right: auto;
	}
	&__close {
		float: right;
		width: 1.33334rem;
		height: 1.33334rem;
		margin-top: 1.33334rem;
		&:hover {
			cursor: pointer;
			rect {
				fill: $gray-6 !important;
			}
		}
	}
	&__filter-set {
		width: 50%;
		float: left;
		@include box; // fpr padding we'll add at larger sizes
	}
	&__heading {
		@include sans-serif;
		padding: 1rem 0;
	}
	&__filter {
		@include sans-serif-bold;
		border-top: 1px solid $dark-turquoise;
		margin-bottom: -1px;
		padding: 1rem 0;
		&--show-all {
			border-top: none;
			border-bottom: 1px solid $dark-turquoise;
		}
	}
	&__link {
		color: #fff;
		&:hover, &--selected {
			color: $gray-6;
		}
	}
	@include breakpoint(smallish) {
		&__filter-set {
			&--left {
				padding-right: $gutter-half-width-smallish;
			}
			&--right {
				padding-left: $gutter-half-width-smallish;
			}
		}
	}
	@include breakpoint(medium) {
		&__filter-set {
			&--left {
				padding-right: $gutter-half-width-medium;
			}
			&--right {
				padding-left: $gutter-half-width-medium;
			}
		}
	}
	.JS & {
		background: rgba(0, 0, 0, .7);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999999;
		margin: 0;
		display: none;
		&__panel {
			position: absolute;
			top: 50%;
			left: $screen-margin-small-with-gutter;
			right: $screen-margin-small-with-gutter;
			transform: translate(0, -50%);
			-ms-transform: translateY(-50%); // not sure why autoprefixer doesn't do this
			@include breakpoint(smallish) {
				left: $screen-margin-smallish-with-gutter;
				right: $screen-margin-smallish-with-gutter;
			}
			@include breakpoint(medium) {
				left: $screen-margin-medium-with-gutter;
				right: $screen-margin-medium-with-gutter;
			}
		}
	}
}