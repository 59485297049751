.testimonial {
	background: $turquoise;
	padding: 2rem 0 3rem;
	&--gray {
		background: $gray-6;
	}
	&__quote {
		@extend %gutters;
		@include h5;
		color: #fff;
		position: relative;
		&:before {
			content: "";
			display: block;
			background: url("/dist/img/quote-marks.svg") left top no-repeat;
			background-size: cover;
			width: 3rem;
			height: 2rem;
			margin-bottom: 1rem;
			opacity: .5;
		}
	}
	&__source {
		@extend %gutters;
		@include sans-serif;
		@include short-top-rule(rgba(255,255,255,.5));
		color: #fff;
	}
	@include breakpoint(smallish) {
		&__quote, &__source {
			width: 66.666666%;
			margin-left: 33.333333%;
		}
		&__quote:before {
			width: 4.5rem;
			height: 3rem;
			position: absolute;
			top: .5rem;
			left: -66px
		}
	}
	@include breakpoint(medium) {
		&__quote:before {
			left: -92px
		}
	}
	@include breakpoint(large) {
		padding: 3rem 0 5rem;
		&__quote:before {
			width: 6rem;
			height: 4rem;
			left: -114px
		}
	}
	@include breakpoint(huge) {
		&__quote, &__source {
			width: 50%;
		}
	}
}