.standfirst {
	background: $gray-6;
	padding: 2rem 0 3rem;
	&__text {
		@extend %gutters;
		@include h2;
		color: #fff;
	}
	&__logo {
		@extend %gutters;
		@include h2;
		color: #fff;
		margin-top: 24px;
		.intro-logo {
			display: block;
			max-height: 80px;
			max-width: 100%;
			width: auto;
			height: auto;
		}
	}
	@include breakpoint(smallish) {
		&__text {
			width: 66.666666%;
		}
		&__logo {
			margin-top: 6px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	@include breakpoint(medium) {
		padding: 3rem 0 5rem;
		&__logo {
			margin-top: 12px;
			.intro-logo {
				max-height: 156px;
			}
		}
	}
}