.read-more-toggle {
	@include sans-serif-bold;
	background: none;
	padding: 0;
	margin: 0;
	border: none;
	width: 100%;
	text-align: left;
	cursor: pointer;
	color: $turquoise;
	outline: none;
	background: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%2349ceca%22%20d%3D%22M8%2010.5l-7-7-1%201%208%208%208-8-1-1z%22/%3E%3C/svg%3E') right center no-repeat;
	background-size: 1.33333rem 1.33333rem;
	&:hover {
		color: $gray-6;
		background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNDY0NjQ2IiBkPSJNOCAxMC41bC03LTctMSAxIDggOCA4LTgtMS0xeiIvPjwvc3ZnPg==');
	}
	&.js-is-active {
		background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNDljZWNhIiBkPSJNLjAxIDFMMSAuMDFsMTQuOTkgMTQuOTkxLS45OS45OXoiLz48cGF0aCBmaWxsPSIjNDljZWNhIiBkPSJNLjAxIDE1LjAwMUwxNSAuMDExbC45OS45OUwxIDE1Ljk5eiIvPjwvc3ZnPg==');
		&:hover {
			background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNDY0NjQ2IiBkPSJNLjAxIDFMMSAuMDFsMTQuOTkgMTQuOTkxLS45OS45OXoiLz48cGF0aCBmaWxsPSIjNDY0NjQ2IiBkPSJNLjAxIDE1LjAwMUwxNSAuMDExbC45OS45OUwxIDE1Ljk5eiIvPjwvc3ZnPg==');
		}
	}
	&--dark {
		&:hover {
			color: #fff;
			background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOCAxMC41bC03LTctMSAxIDggOCA4LTgtMS0xeiIvPjwvc3ZnPg==');
		}
		&.js-is-active:hover {
			background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNLjAxIDFMMSAuMDEgMTUuOTkgMTVsLS45OS45OXoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNLjAxIDE1TDE1IC4wMWwuOTkuOTlMMSAxNS45OXoiLz48L3N2Zz4=');
		}
	}

	&:focus-visible {
    color: $accessibility__text !important;
    background-color: $accessibility__highlight;
		background-image: url('data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBmaWxsPSIjNDY0NjQ2IiBkPSJNLjAxIDFMMSAuMDFsMTQuOTkgMTQuOTkxLS45OS45OXoiLz48cGF0aCBmaWxsPSIjNDY0NjQ2IiBkPSJNLjAxIDE1LjAwMUwxNSAuMDExbC45OS45OUwxIDE1Ljk5eiIvPjwvc3ZnPg==');
    box-shadow: $accessibility__box-shadow;
  }
}