input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  font-size: inherit;
  line-height: inherit;
  padding: .9rem 1.2rem;
  border: 0;
  background-color: #FFF;
  box-shadow: inset 0 0 0 1px $gray-3;
  // border-radius: .4rem;
  box-sizing: border-box;
  width: 100%;

  &:active,
  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 3px $text-color;
  }
}

input[type="radio"],
input[type="checkbox"] {
  width: 2.8rem;
  height: 2.8rem;
  box-shadow: inset 0 0 0 1px $gray-3;
  background-color: #FFF;

  &:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 3px $text-color;
    background-color: $accessibility__highlight;
  }
}

input[type="radio"] {
  border-radius: 999px;
}

label,
.label,
.fui-legend {
  font-weight: 700;
  margin-bottom: 8px;
  display: inline-block;
}

.fui-legend--with-icon {
  display: flex;
  align-items: center;

  img {
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
    height: auto;
    width: 2.8rem;
    margin-right: 1.2rem;
  }

  span {
    display: block;
    flex-grow: 1;
    margin: .1rem 0;
  }
}

// Alert - used to present error summaries or success messages

.alert {
  font-weight: 700;
  margin-bottom: 28px;
  padding-top: 18px;
  padding-bottom: 20px;
  padding-right: 3.2rem;
  padding-left: 2.4rem;
  box-shadow: inset 0 0 0 3px currentColor;
  border-radius: .4rem;
}

.alert--error {
  color: $red-error;
}

.alert--success {
  color: $green-success;
}

// Custom select

.custom-select {
  position: relative;

  select {
    appearance: none;
    margin: 0;
    padding-right: 52px;
  }

  select::-ms-expand {
    display: none;
  }

  &:after {
    content: "";
    width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: $gray-5;
    color: #FFF;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    background-image: url("/dist/images/icons/chevron-down--white.svg");
    background-size: 12.25px auto;
    pointer-events: none;
  }
}

// Custom checkboxes & radio
.custom-input-label {
  position: relative;
  min-height: 2.4rem;
  padding-left: 3.6rem;
  font-weight: 400;
  margin: 0;

  input[type="checkbox"],
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }
}

.custom-input-label__label {
  display: block;
  margin-top: 3px;
}

.custom-input-label__label + .fui-instructions {
  margin-bottom: 0;
  margin-top: 8px;
}

.custom-checkbox,
.custom-radio {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: .4rem;
  box-shadow: inset 0 0 0 1px $gray-6;
  background-color: #FFF;
  transition: all .2s ease-in;
}

.custom-checkbox {
  background-repeat: no-repeat;
  background-position: center;
}

.custom-radio {
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type="checkbox"]:focus-visible ~ .custom-checkbox,
input[type="radio"]:focus-visible ~ .custom-radio {
  box-shadow: inset 0 0 0 3px $gray-6;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-image: url("/dist/images/icons/check--river-blue-darker.svg");
}

input[type="radio"]:checked ~ .custom-radio {
  &:before {
    content: "";
    width: 1.4rem;
    height: 1.4rem;
    background-color: $gray-6;
    border-radius: 100%;
    display: block;
  }
}

[type="file"]::-webkit-file-upload-button {
  @include button(
    $btn__text-colour: $gray-5,
    $btn__background-colour: #FFF,
    $btn__hover-text-colour: #FFF,
    $btn__hover-background-colour: $gray-5
  )
}

//--------//
// Formie //
//--------//

.fui-form {
  margin-top: 36px;
  margin-bottom: 36px;
}

// .fui-form-container {
//   display: flex;
//   flex-direction: column;
// }

.fui-row {
  margin: 0 -12px;
  flex-direction: column;
}

.fui-field {
  margin: 0 12px;
  margin-bottom: 28px;
}

.fui-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fui-instructions,
.field-instructions {
  color: $gray-6;
  margin-bottom: 8px;

  + .fui-input-container {
    margin-top: 8px;
  }
}

.radio-option-instructions {
  display: block;
  margin-top: 8px;
  color: $gray-6;
}

.fui-input-container {
  width: 100%;
}

.fui-fieldset {
  padding: 0;
  border: 0;
}

// Custom dropdown assignment
.fui-type-dropdown .fui-input-container {
  @extend .custom-select;
}

// Custom checkboxes & radios assignment
.fui-radio-label,
.fui-checkbox-label {
  @extend .custom-input-label;
}

// Fields that have multiple sub-fields don't want their own bottom margin
.fui-subfield-fieldset {
  margin-bottom: -28px;
}

.fui-checkbox,
.fui-radio {
  position: relative;
  padding-top: 4px;

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    top: 0;
  }

  input[type="radio"]:checked {
    background-image: url("/dist/img/circle-turquoise.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
  }

  input[type="checkbox"]:checked {
    background-image: url("/dist/img/tick-turquoise.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }
}

.fui-layout-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.fui-layout-vertical .fui-layout-wrap {
  flex-direction: column;
}

// Required inversion
.fui-field:not(.fui-field-required) > .fui-field-container > .fui-label:after{
  content: " (optional)";
}

.fui-required {
  display: none;
}

.fui-alert {
  @extend .alert;
}

.fui-alert-error {
  @extend .alert--error;
}

.fui-alert-success {
  @extend .alert--success;
}

.fui-input.fui-error {
  box-shadow: inset 0 0 0 3px $red-error;
}

.fui-field.fui-error {
  padding-left: 16px;
  border-left: 3px solid $red-error;
  padding-bottom: 8px;
}

.fui-error-message {
  color: $red-error;
  margin-top: 8px;
}

.fui-page-title {
  @extend h3;
}

.fui-repeater-row {
  margin-bottom: 14px;
  padding: 1.5rem;
  border-radius: .4rem;
  border: 2px dashed $gray-4;
}

.fui-type-signature {
  canvas {
    background-color: #FFF;
    box-shadow: inset 0 0 0 1px $gray-5;
    width: 100%;
    height: 150px;
    border-radius: .4rem;
  }
}

.fui-type-calculations input[type="text"] {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: $text-color;
}

.fui-btn-wrapper {
  flex-direction: column;
  align-items: flex-start;

  .fui-submit,
  .fui-save {
    order: initial;
  }
}

// Add chevron to progress/submit button
// .fui-submit:after {
//   content: "";
//   display: block;
//   width: 9px;
//   height: 14px;
//   margin-left: 12px;
//   background-image: url("/dist/images/icons/chevron-right--white.svg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: center;
// }

.fui-submit[data-conditionally-hidden="true"] + .fui-prev {
  display: none;
}

button.fui-prev {
  @include button-reset;
  display: inline-block;
  color: $gray-5;
  margin-top: 20px;

  &:hover {
    color: $gray-6;
  }
}

a.fui-prev {
  display: inline-block;
  text-decoration: none;
  margin-top: 20px;
}

.fui-tabs {
  display: none;
}

.fui-progress-container {
  order: -1; // Hoist it above form title without overwriting formie template
  margin-bottom: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.fui-title {
  margin-top: 0;
  margin-bottom: 36px;
}

.fui-label-below-input label,
.fui-instructions-below-input .fui-instructions  {
  margin-bottom: 0;
  margin-top: 8px;
}

.fui-layout-horizontal {
  .fui-checkbox,
  .fui-radio {
    padding-right: 50px;
  }
}

// International phone number dropdown
.fui-i .fui-form .iti--separate-dial-code {
  .iti__flag-container {
    padding: 2px;
  }

  .iti__flag-box,
  .iti__flag {
    display: none;
  }

  .iti__selected-flag {
    display: flex;
    background-color: $gray-1;
    padding: 0 12.5px 0 10.5px;

    &:not([title]):after {
      content: "Country";
      display: block;
      margin-left: 1rem;
      margin-top: 1px;
      color: $gray-7;
    }
  }

  .iti__arrow {
    border: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: $gray-5;
    background-image: url("/dist/images/icons/chevron-down--white.svg");
    background-repeat: no-repeat;
    background-position: center calc(50% + 2px);
    transition: all .2s ease-in;

    &.iti__arrow--up {
      transform: rotate(180deg);
      background-position: center;
    }
  }

  .iti__selected-dial-code {
    order: 3;
    margin-left: 1rem;
    margin-top: 1px;
    font-size: inherit;
    color: $gray-5;
  }

  .iti__country-list {
    border: 0;
    color: $gray-5;
    border-radius: .4rem;

    li {
      padding: 1.2rem 1.2rem 1.2rem 1.6rem;
      margin: 0;

      &:before {
        display: none;
      }
    }

    .iti__highlight {
      background-color: $gray-1;
    }
  }
}

@include breakpoint(medium) {
  input[type="date"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"],
  select,
  textarea {
    padding: .9rem 1.6rem;
  }

  .alert {
    padding-left: 3.2rem;
  }

  .custom-checkbox,
  .custom-radio {
    width: 3.2rem;
    height: 3.2rem;
  }

  //--------//
  // Formie //
  //--------//

  .fui-legend--with-icon {
    img {
      width: 3.2rem;
      margin-right: 1.6rem;
    }
  }

  .fui-row {
    flex-direction: row;
  }

  .fui-field.fui-error {
    padding-left: 22px;
  }

  .eligibility-checker-form .fui-type-html .fui-input-container {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }

  .fui-submit:after {
    width: 12px;
    height: 18px;
    margin-left: 16px;
  }
}