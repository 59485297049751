.article {
	background: $gray-1;
	min-height: 260px;
	&__standfirst {
		@extend %gutters;
		@include h2;
		margin: 2rem 0 3rem;
	}
	&__what-we-did-and-impact {
		@extend %gutters;
	}

	&__what-we-did {
		margin-bottom: 3rem;
		&__heading {
			@include sans-serif-bold;
			@include short-bottom-rule;
			color: $gray-7;
		}
		&__item {
			@include sans-serif;
			color: $gray-5;
		}
	}

	&__impact-icons {
		margin-bottom: 3rem;

		&__heading {
			margin-bottom: 21px;
		}

		a {
			display: block;
			width: 100%;
			margin-top: 15px;
			@include sans-serif;
			color: $gray-5;
			text-decoration: underline;
		}

		&__icons {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__text {
		@extend %gutters;
	}
	// article__section is used for creating different bg/font colors for chunks of page
	&__section {
		&--light {
			* {
				color: #fff !important;
			}
			p, li, span, figcaption {
				a {
					// background-image: linear-gradient(rgba(255,255,255,.5) 50%, rgba(0,0,0,0) 50%) !important;
					text-decoration-color: #fff !important;
				}
			}
			.article__text ul li:before {
				border-color: rgba(255,255,255,.5);
			}
		}
		// add extra margins to text blocks at start or end of a section
		& .section-wrap .article__text:first-child, & .section-wrap .article__left-heading + .article__text {
			margin-top: 2rem;
		}
		& .section-wrap .article__text:last-child {
			margin-bottom: 3rem;
		}
	}
	&__raw-html {
		@extend %gutters;
		margin: 3rem 0 4rem;
		position: relative; // for absolutely positioning large captions
	}
	// used on non-nav pages eg Privacy
	&__left-heading {
		@extend %gutters;
		margin-top: 2rem;
	}
	@include breakpoint(smallish) {
		&__text, &__raw-html--two-thirds {
			width: 66.666666%;
			margin-left: 33.333333%;
		}
		&__left-heading {
			width: 33.333333%;
			float: left;
		}

	}
	@include breakpoint(medium) {
		&__standfirst {
			width: 100%;
		}

		&__what-we-did-and-impact {
			display: flex;
			flex-wrap: wrap;
		}

		&__what-we-did {
			flex: 0 0 33.333333%;
		}

		&__impact-icons {
			flex: 0 0 calc(66.666666% - 18px);
			padding-left: 18px;
		}
	}
	@include breakpoint(large) {
		&__standfirst {
			width: 66.666666%;
			float: right;
			margin: 3rem 0 5rem;
		}
		&__what-we-did-and-impact {
			width: 33.333333%;
			float: left;
			margin: 3.5rem 0 0;
		}

		&__what-we-did, 
		&__impact-icons {
			flex: 0 0 100%;
			width: 100%;

			a {
				text-align: left;
			}
		}

		&__impact-icons {
			padding-left: 0;
			max-width: 336px;
		}

		&__text {
			width: 50%;
		}
		&__section {
			& .section-wrap .article__text:first-child, & .section-wrap .article__left-heading + .article__text {
				margin-top: 3rem;
			}
			& .section-wrap .article__text:last-child,
			& .section-wrap .icon-and-text:last-child,
			& .section-wrap .data-table:last-child {
				margin-bottom: 4rem;
			}
		}
		&__raw-html {
			margin-top: 4rem;
			margin-bottom: 5rem;
		}
		&__left-heading {
			margin-top: 2.625rem;
		}
	}
}