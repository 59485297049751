.home-hero {
	z-index: -1;
	.section-wrap {
		position: relative;
	}
	position: relative;
	&__text {
		@extend %gutters;
		@include h1;
		color: #fff;
		padding-top: 9rem;
		padding-bottom: 3rem;
		position: relative;
		z-index: 1;
		@include breakpoint(smallish) {
			padding-top: 5rem;
		}
		@include breakpoint(large) {
			padding-top: 9rem;
			padding-bottom: 5rem;
		}
		@include breakpoint(huge) {
			padding-top: 12rem;
		}
	}
	&__images {
		position: relative;
		position: absolute;
		top: -3rem;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		@include breakpoint(smallish) {
			top: -1.2rem;
		}
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		background-size: cover;
		display: none;
		&--1 {
			display: block;
		}
		&--new {
			display: none;
		}
	}
}
.home-standfirst-wrap {
	@include breakpoint(smallish) {
		display: flex;
		flex-wrap: wrap;
	}
}