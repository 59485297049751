.video {
	@extend %gutters;
	margin: 3rem 0 4rem;
	&__wrap {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
	}
	iframe {
		border: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	@include breakpoint(large) {
		margin: 4rem 0 5rem;
	}
}