.see-more-work {
	background: $gray-7;
	padding: 3rem 0;
	&__icon {
		display: block;
		margin: 0 auto 1rem;
	}
	&__text {
		text-align: center;
	}
	&__link {
		color: #fff;
	}
	@include breakpoint(medium) {
		&__icon {
			width: 7.06666667rem;
			height: 4rem;
		}
	}
	@include breakpoint(large) {
		padding: 4rem 0;
		&__icon {
			margin-bottom: 2rem;
		}
	}
}