.home-projects {
	background: #fff;
	@include clearfix;
	&__link {
		display: block;
	}
	&__img-zoom-wrap {
		overflow: hidden;
	}
	&__img {
		height: 20rem;
		background-size: cover !important;
		transition: transform 1s;
	}
	&__text {
		@extend %screen-margins-with-gutters;
		@include box;
		height: 100%;
	}
	&__heading {
		@include sans-serif-bold;
		@include short-bottom-rule(rgba(0,0,0,.15));
		margin-top: 1rem;
	}
	&__tagline {
		@include h3;
		margin-bottom: 3rem;
	}
	&__project:hover {
		.home-projects__img {
			transform: scale(1.05);
		}
	}
	@include breakpoint(smallish) {
		&__project {
			clear: both;
			@include clearfix;
			&--odd {
				background: $gray-1;
			}
		}
		&__img-wrap {
			width: 50%;
			float: left;
			z-index: 1; // pulls image link in front of text area on larger sizes
			position: relative;
		}
		&__img-zoom-wrap {
			margin-right: $gutter-half-width-smallish;
		}
		&__img {
			height: 15rem;
		}
		&__text {
			width: 50%;
			float: right;
			padding-left: $gutter-half-width-smallish;
		}
		&__tagline {
			margin-bottom: 0;
		}
	}
	@include breakpoint(medium) {
		&__img-zoom-wrap {
			margin-right: -$gutter-half-width-medium;			
		}
		&__img {
			height: 20rem;
		}
		&__text {
			width: 50%;
			float: right;
			padding-left: $screen-margin-medium-with-gutter + $gutter-half-width-medium;
		}
		&__heading {
			margin-top: 2rem;
		}
		&__heading, &__tagline {
			width: 100%;
			max-width: 624px;
		}
		&__project {
			&--odd {
				background: none;
			}
			&--even {
				.home-projects__img-wrap {
					float: right;
				}
				.home-projects__img-zoom-wrap {
					margin: 0 0 0 (-$gutter-half-width-medium);
				}
				.home-projects__text {
					float: left;
					padding: 0 ($screen-margin-medium-with-gutter + $gutter-half-width-medium) 0 $screen-margin-medium-with-gutter;
				}
				.home-projects__heading, .home-projects__tagline {
					float: right;
				}
			}
		}
	}
	@include breakpoint(large) {
		&__img {
			height: 26rem;
		}
	}
}