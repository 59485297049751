.newsletter {
	@extend %gutters;
	background: $turquoise;
	padding-top: 2rem;
	padding-bottom: 3rem;
	&__heading {
		@include h6;
		text-align: center;
		color: #fff;
	}
	&__text {
		@include sans-serif;
		text-align: center;
		margin-bottom: 2rem;
	}
	&__label {
		display: none;
	}
	&__email, &__submit {
		width: 100%;
		float: left;
		box-shadow: none !important;
	}
	
	input[type="email"].newsletter__email {
		@include sans-serif;
	}

	input[type="submit"].newsletter__submit {
		@include sans-serif-bold;
		margin-right: 0;
	}

	&__submit:hover {
		background: $gray-7;
	}
	&__email-wrap {
		position: relative; // for mailchimp inline errors
	}
	&__email.mce_inline_error {
		background: pink;
		&::placeholder {
			color: red;
		}
	}
	&__response {
		clear: both;
		padding-top: 2rem;
		@include sans-serif;
		text-align: center;
		color: $text-color;
		a {
			color: $text-color;
			text-decoration: underline;
		}
	}
	@include breakpoint(smallish) {
		// side-by-side buttons
		.section-wrap {
			max-width: 600px;
		}
		&__email-wrap {
			margin-right: 10rem;
		}
		&__email {
			text-align: left;
		}
		&__submit {
			width: 10rem;
		}
	}
	@include breakpoint(medium) {
		&__heading {
			margin-bottom: 1rem;
		}
	}
	@include breakpoint(large) {
		padding-top: 4rem;
		padding-bottom: 5rem;
	}
}

div.mce_inline_error {
	display: none !important;
}