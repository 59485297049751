.caption {
	@include caption;
	clear: both;
	margin-bottom: -2rem; // close up gap under captions so that single line caption doesn't effect space after images (longer captions will start to push content down)

	// this is for 2/3 content to right, caption to left with little rule above
	&--large {
		@include large-caption;
		@include short-top-rule;
		margin: 0 0 2rem;
		// when a large captioned image happens inside of a section with a non-white bg color, use semi-transparent white rule instead of page's accent color
		.article__section--light &:before {
			border-color: rgba(255,255,255,.5);
		}
		@include breakpoint(smallish) {
			position: absolute;
			left: -50%;
			top: 0;
			width: 50%;
			padding: .5rem $gutter-half-width-smallish 0;
			@include box;
		}
		@include breakpoint(medium) {
			padding: .666667rem $gutter-half-width-medium 0;
		}		
	}
}