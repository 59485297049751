.category-icons {
  @extend %gutters;
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;

  &__icon {
    display: block;
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

@include breakpoint(large) {
  .category-icons {
    width: 66.666666%;
    margin-left: 33.333333%;
    margin-top: 3rem;
  }
}