.article-list {
	overflow-x: hidden; // hides last side rule in each row
	&--related-work {
		* {
			color: #fff;
		}
		.article-list__article {
			margin-bottom: 2rem;
			&:after {
				border-color: $gray-4;
			}
		}
	}
	&__article {
		@extend %gutters;
		margin-bottom: 3rem;
		width: 100%;
		float: left;
		position: relative;
		// side rule – last in row gets hidden by overflow-x of container
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: -1px;
			bottom: 0;
			border-right: 1px solid $gray-2;
		}
		&--huge-only {
			display: none;
		}
	}
	&__img-wrap {
		margin-bottom: 1rem;
		overflow: hidden;
	}
	&__img {
		display: block;
		transition: transform 1s;
		.article-list__article:hover & {
			transform: scale(1.05);
		}
		// background images (as used in Work list)
		&--bg {
			height: 20rem;
			background-size: cover !important;
			background-repeat: no-repeat !important;
			@include breakpoint(smallish) {
				height: 13rem;
			}
			@include breakpoint(medium) {
				height: 16rem;
			}
			@include breakpoint(large) {
				height: 21rem;
			}
			@include breakpoint(huge) {
				height: 17rem;
			}
		}
		&--full-bleed {
			margin-left: -$screen-margin-small-with-gutter;
			margin-right: -$screen-margin-small-with-gutter;
			@include breakpoint(smallish) {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	&__large-heading {
		@include h5;
		.article-list__article:hover & {
			color: $slightly-dark-turquoise;
		}
	}
	&__small-heading {
		@include sans-serif-bold;
		&--bottom-rule {
			@include short-bottom-rule(rgba(0,0,0,.15));
		}
	}
	&__p {
		@include sans-serif;
	}
	&__date {
		@include sans-serif;
		color: $gray-3;

		&--dark {
			color: $gray-5;
		}
	}
	&__body-preview {
		@include short-top-rule($turquoise);
		display: none;
	}
	&__read-more {
		@include sans-serif-bold;
		position: absolute;
		bottom: 0;
		left: 0;
		@extend %gutters;
		display: none;
		a {
			color: $turquoise;
			@include no-link;
		}
	}

	&__cat-icons {
		display: flex;
		flex-wrap: wrap;
		margin-top: 16px;

		.cat-icon {
			display: block;
			margin-right: 2px;
			margin-bottom: 2px;
		}
	}

	@include breakpoint(smallish) {
		&--related-work {
			display: flex;
			flex-wrap: wrap;
		}

		&__article {
			width: 50%;
			flex: 0 0 50%;
			&:nth-of-type(2n+1) {
				clear: both;
			}
			&--read-more {
				padding-bottom: 3rem;
			}
		}
		&__body-preview, &__read-more {
			display: block;
		}
	}
	@include breakpoint(huge) {
		&__article {
			width: 33.333333%;
			flex: 0 0 33.333333%;
			&:nth-of-type(2n+1) {
				clear: none;
			}
			&:nth-of-type(3n+1) {
				clear: both;
			}
			&--huge-only {
				display: block;
			}
		}
	}
}