.dev-tools {
	&__overlay {
		width: 100%;	
		height: 10000px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		display: none;
		@extend %screen-margins;
		@include box;
		background: repeating-linear-gradient(
			transparent,
			transparent 1rem,
			rgba(255,0,0,.3) 1rem,
			rgba(255,0,0,.3) 2rem
		) 0 -.5rem;
		background-position: 0 0;
		ul {
			height: 100%;
			max-width: $max-screen-width;
			margin: 0 auto;
		}
		&__col {
			height: 100%;
			width: 50%;
			@extend %gutters;
			display: none;
			float: left;
			div {
				height: 100%;
				background: rgba(255, 0, 0, .3);
			}
			&--small {
				display: block;
			}
			
			/******************************************************************************************
			Update the below to change number of columns at various breakpoints...
			******************************************************************************************/
			
			@include breakpoint(smallish) {
				width: 16.666666%;
				display: block;
			}
		}
	}
	&__grid-btn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 25px;
		height: 25px;
		z-index: 2000;
		background: #fff url('data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%2225px%22%20height%3D%2225px%22%20viewBox%3D%220%200%2025%2025%22%20style%3D%22enable-background%3Anew%200%200%2025%2025%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Crect%20x%3D%223%22%20y%3D%223%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2210%22%20y%3D%223%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2217%22%20y%3D%223%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%223%22%20y%3D%2210%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2210%22%20y%3D%2210%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2217%22%20y%3D%2210%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%223%22%20y%3D%2217%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2210%22%20y%3D%2217%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3Crect%20x%3D%2217%22%20y%3D%2217%22%20width%3D%225%22%20height%3D%225%22/%3E%0A%3C/svg%3E%0A') top right no-repeat;
		cursor: pointer;
		opacity: .3;
		&:hover {
			opacity: .7;
		}
	}
	&__log {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 200px;
		height: 65px;
		background: #fff;
		overflow: hidden;
		z-index: 10000;
		display: none;
		&__text {
			font: 12px/12px arial;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 3px;
		}
	}
}
