/******************************************************************************************
COLORS
******************************************************************************************/

$text-color: #464646;
$link-color: #8b312a;
$gray-1: #f0f0ee;
$gray-2: #c8c8c8;
$gray-3: #aaaaaa;
$gray-4: #969696;
$gray-5: #646464;
$gray-6: #464646;
$gray-7: #1e1e1e;
$turquoise: #49ceca;
$slightly-dark-turquoise: #36c8c3;
$dark-turquoise: #38a19d;
$pink: #ffa3ba;
$brown: #9b6752;

$red-error: #d80000;
$green-success: #28a745;

//---------//
// Buttons //
//---------//

$btn__background-colour: $gray-6;
$btn__hover-background-colour: $gray-7;
$btn__text-colour: #FFF;
$btn__border-radius: 0;
$btn__padding: 10px 16px;
$btn__padding-desktop: 14px 32px;
$btn__font-weight: 600;

// Accessibility focus state colours
$accessibility__highlight: #FFDD00;
$accessibility__text: #000;
$accessibility__box-shadow: 0 3px $accessibility--text;
$accessibility__inner-box-shadow: inset 0 0 0 3px $accessibility--text;

/******************************************************************************************
LAYOUT VARIABLES
******************************************************************************************/

// SCREEN SIZES

$max-screen-width: 1440px;
$smallish-screen: 480px;
$medium-screen: 768px;
$large-screen: 992px;
$huge-screen: 1200px;

// GUTTERS

$gutter-half-width-small: 12px;
$gutter-half-width-smallish: 12px;
$gutter-half-width-medium: 24px;
$screen-margin-small: 0;
$screen-margin-smallish: 12px;
$screen-margin-medium: 24px;
$screen-margin-small-with-gutter: $screen-margin-small + $gutter-half-width-small;
$screen-margin-smallish-with-gutter: $screen-margin-smallish + $gutter-half-width-smallish;
$screen-margin-medium-with-gutter: $screen-margin-medium + $gutter-half-width-medium;

/******************************************************************************************
MISC
******************************************************************************************/

$header-height-small: 7rem;
$header-height-smallish: 4rem;
