/******************************************************************************************
SINGLE IMAGES
******************************************************************************************/

.image {
	@extend %gutters;
	margin: 3rem 0 4rem;
	position: relative;
	&__img {
		display: block;
		width: 100%; // Safari doesn't load in larger responsive images when you widen your browser, so this at least makes sure the layout doesn't get messed up
		height: auto;
	}
	&--no-bottom-margin {
		margin-bottom: 0 !important;
	}
	@include breakpoint(smallish) {
		&--two-thirds {
			width: 66.666666%;
			margin-left: 33.333333%;
		}
	}
	@include breakpoint(large) {
		margin-top: 4rem;
		margin-bottom: 5rem;
	}
}

/******************************************************************************************
TWO IMAGES
******************************************************************************************/

.two-images {
	margin: 0; // our css reset doesn't reset figure elements!
	@include breakpoint(smallish) {
		margin: 3rem 0 4rem;
		@include clearfix;
		.image {
			margin: 0;
			width: 50%;
			float: left;
		}
	}
	@include breakpoint(large) {
		margin-top: 4rem;
		margin-bottom: 5rem;
	}
}

/******************************************************************************************
MULTI IMAGES

If any changes are made here to the widths of images at different breakpoints, remember to also update any twig code that outputs multi images (eg. /work/_entry.twig)
******************************************************************************************/

.multi-image {
	margin: 3rem 0 4rem;
	@include clearfix;
	.image {
		float: left; // saves floating them all left below!
		margin: 0 0 2rem;
		&--last {
			margin: 0;
		}
		@include breakpoint(medium) {
			margin-bottom: 3rem; // keep this above media queries below so they can override for images in last rows of each set
		}		
	}
	&--tight-margins {
		@include breakpoint(medium) {
			padding: 0 9px; // this "magic" number is $gutter-half-width-medium (24px) - 1rem (15px)
			.image {
				padding-left: 1rem;
				padding-right: 1rem;
				margin-bottom: 2rem;
			}
			.caption {
				// this "magic" number is $gutter-half-width-medium (24px) - the other "magic number" padding above (9px)
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
	&--4perRow .image {
		width: 50%;
		float: left;
		&:nth-child(odd) {
			clear: both;
		}
		&--last-row-of-2 {
			margin: 0;
		}
	}
	.caption {
		@extend %gutters;
	}
	@include breakpoint(smallish) {
		&--2perRow .image, &--2perRow3366 .image, &--2perRow6633 .image {
			width: 50%;
			&:nth-child(odd) {
				clear: both;
			}
			&--last-row-of-2 {
				margin: 0;
			}
		}
		&--2perRow3366 .image {
			&:nth-child(odd) {
				width: 33.333333%;		
			}
			&:nth-child(even) {
				width: 66.666666%;		
			}
		}
		&--2perRow6633 .image {
			&:nth-child(odd) {
				width: 66.666666%;		
			}
			&:nth-child(even) {
				width: 33.333333%;		
			}
		}
		&--3perRow .image {
			width: 33.333333%;
			&:nth-child(3n+1) {
				clear: both;
			}
			&--last-row-of-3 {
				margin: 0;
			}
		}
	}
	@include breakpoint(medium) {
		&--4perRow .image {
			width: 25%;
			&:nth-child(odd) {
				clear: none;
			}
			&:nth-child(4n+1) {
				clear: both;
			}
			&--last-row-of-2 {
				margin: 0 0 2rem;
			}
			&--last-row-of-4 {
				margin: 0;
			}
		}
	}
	@include breakpoint(large) {
		margin: 4rem 0 5rem;
	}
}










/* DEPRECIATED!!!!!!! */

.four-images {
	border: 10px solid red;
	margin: 3rem 0 4rem;
	clear: both;
	@include clearfix;
	.image {
		margin: 0;
		width: 50%;
		float: left;
		&--1, &--2 {
			margin-bottom: 2rem;
		}
		&--3 {
			clear: both;
		}
	}
	.caption {
		@extend %gutters;
	}
	@include breakpoint(medium) {
		margin-top: 4rem;
		margin-bottom: 5rem;
		.image {
			width: 25%;
			clear: none;
		}
	}
}

.image-group {
	border: 10px solid red;
	margin: 3rem 0 4rem;
	.image {
		margin: 0 0 2rem;
		/* DOESN'T WORK!!!!! */
		&:last-child {
			margin: 0
		}
	}
	.caption {
		@extend %gutters;
	}
	@include breakpoint(smallish) {
		@include clearfix;
		.image {
			width: 50%;
			float: left;
			// tighter internal padding in .image-group
			&:nth-child(odd) {
				padding-right: 1rem;
			}
			&:nth-child(even) {
				padding-left: 1rem;
			}
			&--last-row {
				margin: 0;
			}
		}
	}
	@include breakpoint(large) {
		margin: 4rem 0 5rem;
	}
}