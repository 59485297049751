.related-work {
	background: $gray-6;
	padding-bottom: 1rem;
	&__heading {
		@extend %gutters;
		@include h2;
		margin: 2rem 0 3rem;
		color: #fff;
	}
	@include breakpoint(large) {
		padding-bottom: 3rem;
		&__heading {
			margin: 3rem 0 5rem;
		}
	}
}