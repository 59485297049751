// need to match headerHeight value in main.js (line 100)
// adding this style to prevent the hero section hidden under header while js loading
.main-content {
	padding-top: 108px;
	@media only screen and (min-width: 395px) {
		padding-top: 84px;
	}
	@include breakpoint(medium) {
		padding-top: 60px;
	}
}

.page-header {
	padding-top: 2rem;
	padding-bottom: 3rem;
	background: $turquoise; // default colour

	&__heading {
		@extend %gutters;
		@include h4;
		@include short-bottom-rule(rgba(255,255,255,.5));
		color: #fff;
		a {
			color: #fff;
			&:hover {
				opacity: .7;
			}
		}

		&--lg {
			@include h2;
		}
	}

	&__subheading {
		@extend %gutters;
		@include h2;
		color: #fff;

		&--sm {
			@include h4;
		}
	}

	&__info {
		@extend %gutters;
		@include large-caption;
		color: #fff;
		margin: 1rem 0 -1rem;
	}

	@include breakpoint(smallish) {
		&__subheading {
			width: 66.666666%;
		}
	}
	@include breakpoint(medium) {
		&__heading:after {
			padding-bottom: 1rem;
			margin-bottom: 1rem;
		}
		&__subheading {
			width: 100%;
		}
	}
	@include breakpoint(large) {
		padding-top: 3rem;
		padding-bottom: 5rem;
		&__subheading {
			width: 66.666666%;
		}
	}
}
