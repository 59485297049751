.about-intro {
	background: $gray-1;
	&__text {
		@extend %gutters;
		padding-top: 2rem;
		padding-bottom: 2rem;
		p {
			margin: 1rem 0;
		}
		@include breakpoint(smallish) {
			width: 66.666666%;
			margin-left: 33.333333%;
		}
		@include breakpoint(large) {
			width: 50%;
			padding-top: 3rem;
			padding-bottom: 4rem;
		}		
	}
}