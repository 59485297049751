@mixin button($btn__text-colour, $btn__background-colour, $btn__hover-text-colour, $btn__hover-background-colour) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn__background-colour;
  color: $btn__text-colour;
  border: 0;
  outline: 0;
  border-radius: $btn__border-radius;
  padding: $btn__padding;
  @include sans-serif;
  font-weight: $btn__font-weight;
  text-decoration: none;
  transition: all .2s ease-in;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  &:hover {
    color: $btn__hover-text-colour;
    background-color: $btn__hover-background-colour;
  }

  &:focus-visible {
    color: $accessibility__text !important;
    background-color: $accessibility__highlight;
    box-shadow: $accessibility__inner-box-shadow;
  }

  @include breakpoint(medium) {
    padding: $btn__padding-desktop;
    line-height: 30px;
  }
}

.button,
button {
  // exclude read more toggle buttons
  &:not(.read-more-btn, .read-more-toggle) {
    @include button($btn__text-colour, $btn__background-colour, $btn__text-colour, $btn__hover-background-colour);
  }
}