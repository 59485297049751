.illustrated-list {
	&__heading {
		@extend %gutters;
		margin: 2rem 0 3rem;
	}
	&__item {
		padding-bottom: 2rem;
		&__img-wrap {
			@extend %left-gutter;
		}
		&__img {
			width: 10rem;
			height: 10rem;
			display: block;
			margin-bottom: 1rem;
			border-radius: 50%;
		}
		&__text {
			@extend %gutters;
			p {
				margin-bottom: 1rem;
			}
			ul {
				margin-top: 0; // for services list on about us page, currently not used anywhere else...
			}
		}
		&__heading {
			@include sans-serif-bold;
			color: $gray-7;
			@include short-bottom-rule($turquoise);
		}
		&__subheading {
			@include sans-serif;
			color: $gray-5;
		}
	}
	&--about {
		.illustrated-list__item {
			&:nth-child(1) {
				.illustrated-list__item__heading:before, li:before {
					border-color: $pink;
				}			
			}
			&:nth-child(2) {
				.illustrated-list__item__heading:before, li:before {
					border-color: $brown;
				}			
			}
			&:nth-child(3) {
				li:before {
					border-color: $turquoise;
				}			
			}
		}
	}
	&--people {
		background: $gray-1;
		.illustrated-list__item {
			padding-bottom: 1rem;
			&__text {
				@extend %thin-bottom-rule-grey-2;
				padding-bottom: 2rem;
			}
			&__body {
				margin-bottom: 1rem;
			}
			&__heading:before {
				border-color: $gray-2;
			}
			// remove bottom rule from last person on page
			&--last {
				.illustrated-list__item__text:after {
					content: none;
				}
			}
		}
	}
	// side-by-side image and headings on mobile
	@include below-breakpoint(smallish) {
		&--people {
			.illustrated-list__item {
				&__img {
					float: left;
				}
				&__heading {
					padding-top: 1rem;
					margin-left: 11rem;
				}
				&__heading:before {
					content: none;
				}
				&__body {
					clear: both;
				}
				&--no-img {
					.illustrated-list__item__heading {
						padding-top: 0;
						margin-left: 0;
					}
				}
			}
		}
	}
	@include breakpoint(smallish) {
		&__item {
			clear: both;
			@include clearfix;
			&__img-wrap {
				float: left;
				margin-bottom: 0;
			}
			&__text {
				width: 66.666666%;
				float: right;
			}
		}
		&--people {
			.illustrated-list__item {
				padding-bottom: 2rem;
			}
		}
		&--about {
			.illustrated-list__item__img {
				width: 11rem;
				height: 11rem;
			}
		}
	}
	@include breakpoint(medium) {
		&--about {
			.illustrated-list__item__img {
				width: 14rem;
				height: 14rem;
			}
		}
	}
	@include breakpoint(large) {
		padding-bottom: 2rem;
		&__heading {
			margin-top: 3rem;
			margin-bottom: 5rem;
			float: left; // stops top margin collapsing with items above when heading is in middle of list
			clear: both;
		}
		&__item {
			width: 50%;
			float: left;
			clear: none;
			&--i2 {
				clear: both;
			}
			&__img-wrap, &__text {
				float: none;
				width: 100%;
			}
			&__img-wrap {
				margin-bottom: 1rem;
			}
		}
		&--people {
			.illustrated-list__item {
				// show full biogs from this size up
				padding-bottom: 3rem;
				&__body {
					margin-bottom: 0;
				}
				&__text {
					padding-bottom: 0;
					&:after {
						content: none;
					}
				}
			}
		}
		&--about {
			.illustrated-list__item__img {
				width: 15rem;
				height: 15rem;
			}
		}
		// this one goes straight from 1 per row to 3
		&--1-then-3 {
			.illustrated-list__item {
				width: 33.333333%;
				&--i2 {
					clear: none;
				}
				&--i3 {
					clear: both;
				}
			}
		}
	}
	@include breakpoint(huge) {
		&__item {
			width: 33.333333%;
			&--i2 {
				clear: none;
			}
			&--i3 {
				clear: both;
			}
		}
		&--about {
			.illustrated-list__item__img {
				width: 18rem;
				height: 18rem;
				margin: 0 auto 5rem;
			}
		}
	}		
}