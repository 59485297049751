.wj-cc {
   background-color: $gray-6;
   &.wj-cc--hidden {
      display: none;
      height: 0;
   }
   .section-wrap {
      color: #fff;
      padding-top: 2rem;
      padding-bottom: 2.5rem;
   }
   .article__left-heading, .article__text p {
      margin-bottom: 1rem;
   }    
   .article__text p {
      color: $gray-1;
      a {
         color: #fff;
         text-decoration: underline;
         text-decoration-thickness: 1px;
         text-underline-offset: 2px;
      }
   }
   .article__left-heading {
      color: $gray-1;
      margin-top: 0;
   }   
   .btn {
      padding: .5rem 1.125rem .5625rem;
      margin-bottom: .25rem;
      height: auto;
      background: $gray-1;
      color: $gray-6;
      &:hover {
         background: #fff;
         color: $gray-7;
      }
   }
   .wj-cc__buttons {
      padding-top: .5rem;
   }
   @include breakpoint(smallish) {
      .article__left-heading {
         font-size: 1.666667rem;
         line-height: 1.2;
      }
   }
}