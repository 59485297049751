.follow {
	@include clearfix;
	&__item {
		float: left;
		background: $gray-6;
		margin-right: 1rem;
		&:hover {
			background: turquoise;
		}
	}
	&__svg {
		display: block;
	}
}