.site-footer {
	background: $gray-7;
	.rule {
		@include short-bottom-rule($gray-6);
	}
	&__link {
		color: #fff;
		@include no-link;
	}
	&__contact {
		@extend %left-gutter;
		padding-top: 2rem;
		padding-bottom: 3rem;
		&--address {
			@extend %top-rule-grey;
		}
		p {
			@include sans-serif-bold;
			color: #fff;
		}
		a {
			@include no-link; // for auto-created links on mobiles
			color: #fff;
			text-decoration: none;
		}
		&__tel {
			margin-bottom: 2rem;
		}
		&__reg {
			font-weight: 300 !important;
			color: $gray-4 !important;
		}
	}
	&__links {
		@extend %gutters;
		@extend %top-rule-grey;
		padding-top: 2rem;
		padding-bottom: 3rem;
		p {
			@include sans-serif-bold;
			color: #fff;
		}
		a {
			@include no-link; // for auto-created links on mobiles
			color: #fff;
			text-decoration: none;
		}
		&__tel {
			margin-bottom: 2rem;
		}
		&__reg {
			font-weight: 300 !important;
			color: $gray-4 !important;
		}
	}
	&__logos {
		@extend %gutters;
		@extend %top-rule-grey;
		padding-top: 2.5rem;
		padding-bottom: 3rem;
		&__logo {
			display: block;
		}
		.logos-members {
			display: flex;
			align-items: center;
			margin-top: 2.5rem;
			a {
				margin-right: 1.625rem;
				&:last-child {
					margin-right: 0;
				}
			}
			.site-footer__logos__logo {
				height: auto;
			}

			.site-footer__logos__logo--bcorp {
				width: 3.5rem;
			}
			.site-footer__logos__logo--dba {
				width: 5rem;
			}
		}
	}
	@include breakpoint(smallish) {
		&__contact {
			width: 50%;
			float: left;
		}
		&__contact--address:before {
			content: none;
		}
		&__links {
			clear: both;
			&__tweet {
				width: 66.666666%;
			}
		}
	}
	@include breakpoint(medium) {
		&__logos {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logos-members {
				margin-top: 0;
			}
		}
	}
	@include breakpoint(large) {
		&__contact {
			width: 33.333333%;
		}
		&__links {
			width: 33.333333%;
			float: left;
			clear: none;
			&:before {
				content: none;
			}
		}
		&__logos {
			clear: both;
		}
		&__contact, &__links {
			padding-top: 4rem;
			padding-bottom: 5rem;
		}
	}
}