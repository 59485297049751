html {
	font-size: 75%; // 12px
	-webkit-text-size-adjust: none; // prevent iOS from resizing small text
	@include breakpoint(768px) {
		font-size: 93.75%; // 15px
	}
}

/******************************************************************************************
WEBFONTS
******************************************************************************************/
$fonts_dir: '/dist/fonts/';
@font-face {
	font-family: 'geomanist';
	src: url($fonts_dir + 'geomanist-medium-webfont.eot'); /* IE9 Compat Modes */
	src: url($fonts_dir + 'geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url($fonts_dir + 'geomanist-medium-webfont.woff2') format('woff2'), /* Super Modern Browsers */
		url($fonts_dir + 'geomanist-medium-webfont.woff') format('woff'), /* Pretty Modern Browsers */
		url($fonts_dir + 'geomanist-medium-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
		url($fonts_dir + 'geomanist-medium-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'geomanist-bold';
	src: url($fonts_dir + 'geomanist-bold-webfont.eot'); /* IE9 Compat Modes */
	src: url($fonts_dir + 'geomanist-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url($fonts_dir + 'geomanist-bold-webfont.woff2') format('woff2'), /* Super Modern Browsers */
		url($fonts_dir + 'geomanist-bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */
		url($fonts_dir + 'geomanist-bold-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
		url($fonts_dir + 'geomanist-bold-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

/******************************************************************************************
FONT MIXINS
******************************************************************************************/

* {
	-webkit-font-smoothing: antialiased; // less blurry fonts for chrome
	-moz-osx-font-smoothing: grayscale; // less blurry fonts for FF
}

@mixin body {
	// 16px/24px
	font-family: "PT Serif", Georgia, serif;
	font-size: 1.333334rem;
	font-weight: 300;
	line-height: 1.5;
}
@mixin sans-serif {
	// 16px/24px
	font-family: "adelle-sans", Arial, sans-serif;
	font-size: 1.333334rem;
	font-weight: 300;
	line-height: 1.5;
}
@mixin sans-serif-bold {
	// 16px/24px
	font-family: "adelle-sans", Arial, sans-serif;
	font-size: 1.333334rem;
	font-weight: 600;
	line-height: 1.5;
}
@mixin h1 {
	// 48px/48px
	font-family: "geomanist", Arial, sans-serif;
	font-size: 4rem;
	font-weight: normal;
	line-height: 1;
	letter-spacing: 0.01em;
	word-spacing: -0.07em;
	@include breakpoint(smallish) {
		// 72px/72px
		font-size: 6rem;
	}
	@include breakpoint(huge) {
		// 105px/105px;
		font-size: 7rem;
	}
}
@mixin h2 {
	// 20px/24px
	font-family: "geomanist-bold", Arial, sans-serif;
	font-size: 1.666667rem;
	font-weight: normal;
	line-height: 1.2;
	letter-spacing: 0.01em;
	word-spacing: -0.07em;
	@include breakpoint(medium) {
		// 40px/48px
		font-size: 2.666667rem;
	}
	@include breakpoint(huge) {
		// 50px/56px
		font-size: 3.333334rem;
		line-height: 1.12;
	}
}
@mixin h3 {
	// 20px/24px
	font-family: "geomanist-bold", Arial, sans-serif;
	font-size: 1.666667rem;
	font-weight: normal;
	line-height: 1.2;
	letter-spacing: 0.01em;
	word-spacing: -0.07em;
	@include breakpoint(large) {
		// 40px/48px
		font-size: 2.666667rem;
	}
	@include breakpoint(huge) {
		// 50px/56px
		font-size: 3.333333rem;
		line-height: 1.12;
	}
}
@mixin h4 {
	// 16px/24px
	font-family: "adelle-sans", Arial, sans-serif;
	font-size: 1.333334rem;
	font-weight: 600;
	line-height: 1.5;
	@include breakpoint(medium) {
		// 25px/30px
		font-family: "geomanist-bold", Arial, sans-serif;
		font-size: 1.666667rem;
		line-height: 1.2;
	}
	@include breakpoint(large) {
		// 30px/36px
		font-size: 2rem;
	}
}
@mixin h5 {
	// 20px/24px;
	font-family: "geomanist-bold", Arial, sans-serif;
	font-size: 1.666667rem;
	font-weight: normal;
	line-height: 1.2;
	letter-spacing: 0.01em;
	word-spacing: -0.07em;
	@include breakpoint(large) {
		// 30px/36px
		font-size: 2rem;		
	}
}
@mixin h6 {
	// 16px/24px
	font-family: "adelle-sans", Arial, sans-serif;
	font-size: 1.333334rem;
	font-weight: 600;
	line-height: 1.5;
	@include breakpoint(medium) {
		// 40px/48px
		font-family: "geomanist-bold", Arial, sans-serif;
		font-size: 2.666667rem;
		line-height: 1.2;
		letter-spacing: 0.01em;
		word-spacing: -0.07em;
	}
	@include breakpoint(huge) {
		// 50px/56px
		font-size: 3.333334rem;
		line-height: 1.12;
	}
}
@mixin caption {
	// 12px/16px
	font-family: "adelle-sans", Georgia, serif;
	font-size: 1rem;
	line-height: 1.333334rem;
	font-weight: 300;
	color: $gray-5;
	padding-top: .666667rem;
}
@mixin large-caption {
	// 16px/24px
	font-family: "adelle-sans", Georgia, serif;
	font-size: 1rem;
	line-height: 1.333334rem;
	font-weight: 300;
	color: $gray-5;
	@include breakpoint(large) {
		// 20px/30px
		font-size: 1.333334rem;
		line-height: 1.5;
	}
}
@mixin link {
	color: inherit;
	// background-image: linear-gradient($turquoise 50%, rgba(0,0,0,0) 50%);
	// background-repeat: repeat-x;
	// background-size: 2px 2px;
	// background-position: 0 1.15em;
	// @include breakpoint(medium) {
	// 	background-position: 0 1.1em;
	// }
	text-decoration: underline;
  text-decoration-thickness: 1px;
	text-decoration-color: $turquoise;
  text-underline-offset: 2px;
}
@mixin no-link {
	background: none;
}

/******************************************************************************************
TEXT STYLES
******************************************************************************************/

p, label, li, table {
	@include body;
	color: $text-color;
}
h1 {
	@include h2;
	color: $text-color;	
}
h2 {
	@include h2;
	color: $text-color;	
}
h3 {
	@include h2;
	color: $text-color;	
}
h4, h5, h6, thead {
	@include sans-serif-bold;
	color: $text-color;	
}
li {
	margin-bottom: 0;
}
ul {
	@include body;
	color: $text-color;
}
strong {
	font-weight: bold;
}
em {
	font-style: italic;
}
a {
	text-decoration: none;
}
a:focus-visible, a:active {
	outline: none;
}
img {
	max-width: 100%;
}
// link styles
p, li, span {
	a {
		@include link;
	}
}
figcaption {
	a {
		@include link;
		background-position: 0 1.25em;
		@include breakpoint(medium) {
			background-position: 0 1.25em;
		}
	}
}

/******************************************************************************************
FORMS
******************************************************************************************/

input, textarea, select, button {
	appearance: none;
	border-radius: 0;
}
.input, .textarea, .btn, .select {
	@include sans-serif;
	border: none;
	padding: 1rem 1.6rem;
	width: auto;
	@include box;
	text-align: center;
	color: $text-color;
}
.input, .btn {
	height: 4rem; // IE9 (an poss IE10-11!) needs this to get height exactly right
}
.btn {
	font-weight: 600;
	background: $gray-6;
	color: #fff;
	cursor: pointer;
	&:hover {
		background: $gray-7;
	}
}

.select {
	display: inline-block;
	// remove select arrows
	appearance: none; text-indent: 1px; text-overflow: ''; // removes arrow from FF - see http://css-tricks.com/almanac/properties/a/appearance/
	background: $turquoise;
	@include sans-serif-bold;
	padding: .5rem 1.3rem;
	&:after {
		content: '';
		display: inline-block;
		width: 0;
		border-style: solid;
		border-width: 1rem .6rem 0;
		border-color: $gray-6 transparent transparent;
		margin-left: .9rem;
		position: relative;
		top: .05rem;
	}
	&:hover {
		background: lighten($turquoise, 8%);
	}
}
// remove select arrows on IE
select::-ms-expand {
		display: none;
}

/******************************************************************************************
WYSIWYG
*******************************************************************************************/

.wysiwyg {
	p, table {
		margin: 1rem 0;
	}
	.indent-block {
		@include sans-serif;
		border-left: 4px solid $turquoise;
  	padding: 12px 0 12px 16px;

		@include breakpoint(large) {
			border-left: 5px solid $turquoise;
  		padding: 15px 0 15px 20px;
		}
	}
	h2 {
		@include h4;
		margin: 2rem 0 1rem;
		color: $gray-7;
	}
	h3 {
		@include sans-serif-bold;
		color: $gray-7;
	}
	h4 {
		color: $gray-7;
	}
	ul, ol {
		margin: 1rem 0;
	}
	ul li {
		padding-left: 1.8rem;
		position: relative;
		&:before {
			@include sans-serif;
			content: '';
			width: 1.3333rem;
			border-bottom: 2px solid $turquoise; // default colour
			position: absolute;
			top: 1rem;
			left: 0;
		}
	}
	ol {
		counter-reset: dots;
	}
	li {
		margin-bottom: 6px;

		&:last-child {
			margin-bottom: 0;
		}
	}
	ol li {
		padding-left: 1.8rem;
		position: relative;
		&:before {
			@include sans-serif;
			counter-increment: dots;
      content: counter(dots, decimal) ".";
      font-weight: 600;
      width: 1.8rem;
			position: absolute;
      left: 0;
		}
	}
	sub {
		position: relative;
		top: 2px;
		font-size: .8em;
	}	
	table {
		border-spacing: 0;
		border-collapse: collapse;
		table-layout: fixed;
	}  
	table, thead, tbody, tr {
		width: 100%;
	}  
	tr {
		vertical-align: top;
	}  
	th, td {
		padding: 8px;
		border: 1px solid $gray-3;
	}
	th, thead {
		font-weight: 600;
	}
	// form
	label {
		display: block;
		margin-top: .25rem;
		margin-bottom: .25rem;
	}
	input {
		&[type=submit] {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
		&[type=radio], &[type=checkbox] {
			position: relative;
			width: 1.75rem;
			height: 1.75rem;
			display: inline-block;
			margin-right: .25rem;
			margin-bottom: -.375rem;
			border: 2px solid $gray-5;
			&:checked {
				&:before {
				content: '';
				display: block;
				position: absolute;
				}
			}
			&:hover {
				background-color: #fff;      
			}
			&:focus-visible {
				box-shadow: none;
			}
		}
		&[type=radio] {
			border-radius: 50%;
			&:checked {
				border: 2px solid $gray-7;
				&:before {
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background-color: $gray-7;
					border: 4px solid #fff;
					box-sizing: border-box;
				}
			}
		}
		&[type=checkbox] {
			&:checked {
				&:before {
				width: 42.5%;
				height: 62.5%;
				top: 0;
				left: .125rem;
				border-bottom: 3px solid;
				border-right: 3px solid;
				transform: rotate(45deg) translateY(-.125rem);
				}
			}
		}
	}
}