.form {
  @extend %gutters;
  margin: 2rem 0 3rem;
  @include body;

  @include breakpoint(smallish) {
    width: 66.666666%;
    margin-left: 33.333333%;
  }

  @include breakpoint(large) {
    width: 50%;
  }
}