.data-accordion {
  @extend %gutters;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @include sans-serif;

  &__name {
    margin-bottom: 1rem;
    @include h5;
  }

  &__accordion {
    margin-left: -16px;
    margin-right: -16px;

    &:nth-child(odd) {
      .data-accordion__head, 
      .data-accordion__content {
        background-color: #f7f7f7;
      }
    }

    .data-accordion__head--bold {
      margin-top: 30px;
      margin-bottom: 24px;
      background-color: transparent;
    }

    &.open {
      .data-accordion__head {
        svg {
          transform: rotate(180deg);
        }
      }

      .data-accordion__content {
        flex-direction: column;
      }

      .data-accordion__content-item-text {
        display: block;
      }
    }
  }

  &__head {
    padding-top: 14px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    @include sans-serif-bold;
    text-decoration: underline;
    text-underline-offset: 2px;
    cursor: pointer;

    &--bold {
      @include h5;
      text-decoration: none;
      cursor: auto;
    }

    svg {
      margin-left: 8px;
    }
  }

  &__content {
    display: flex;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__content-item {
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-right: 2px;
  }

  &__content-item-text {
    display: none;
    margin-left: 12px;
  }
}

.data-table {
  display: none;
  @extend %gutters;
  @include sans-serif;

  &__name {
    margin-bottom: 1rem;
    @include sans-serif-bold;
  }

  &__table {
    width: 100%;
    @include sans-serif;
  }

  &__cell {
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid #dedede;
    padding: .25rem 0;

    &--head {
      border-bottom: none;
      padding-top: .5rem;
      @include sans-serif-bold;
    }

    &--icon {
      width: 40px;
    }

    &--left {
      text-align: left;
      padding-right: .5rem;
    }

    &:nth-child(even) {
      background-color: #f7f7f7;
    }
  }

  &__icon {
    display: block;
  }

  &__dot {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    background-color: $gray-5;
    border-radius: 50%;

    &--high {
      width: 24px;
      height: 24px;
    }

    &--low {
      width: 8px;
      height: 8px;
    }

    span {
      display: none;
    }
  }

  &__info {
    color: $gray-6;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 83.333333%;
    margin-top: 24px;

    .dots {
      display: flex;
      align-items: center;
      margin: 2px 6px 0;
    }

    .dot {
      width: 16px;
      height: 16px;
      background-color: $gray-5;
      border-radius: 50%;
      margin: 0 3px;

      &--lg {
        width: 24px;
        height: 24px;
      }
      
      &--sm {
        width: 8px;
        height: 8px;
      }
    }
  }
}

@include breakpoint(smallish) {
  .data-accordion {
    width: 66.666666%;
    margin-left: 33.333333%;

    &__accordion {
      margin-right: 0;
    }
  }

  .data-table {
    &__name {
      padding-left: 6px;
      width: 66.666666%;
      margin-left: 33.333333%;
    }
  }
}

@include breakpoint(medium) {
  .data-table {
    &__name {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@include breakpoint(huge) {
  .data-accordion {
    display: none;
  }

  .data-table {
    display: block;
    margin-top: 4rem;
    margin-bottom: 3rem;

    &__table {
      width: 83.333333%;
    }
  }
}