.parallax {
	overflow: hidden;
	// .parallax doesn't have to contain .parallax__img – first child will always get parallaxed, whatever it is!
	& > * {
		padding-bottom: 6rem;
		margin-bottom: -6rem;
		padding-bottom: 20vw;
		margin-bottom: -20vw;
		position: relative;
	}
	// standard image as in project pages
	&__img {
		height: 20rem;
		background-size: cover !important;
		@include breakpoint(smallish) {
			height: 24rem;
		}		
		@include breakpoint(medium) {
			height: 29rem;
		}		
		@include breakpoint(large) {
			height: 37rem;
		}		
		@include breakpoint(huge) {
			height: 45rem;
		}
	}
}