.icon-and-text {
  @extend %gutters;
  margin-top: 3rem;
  margin-bottom: 2rem;

  &__item {
    @include sans-serif;
    display: flex;
    margin: 2rem 0;
  }

  &__icon {
    flex: 0 0 60px;
    height: 60px;
    margin-right: 1.25rem;
  }

  &__content-title {
    @include sans-serif-bold;
  }
}

@include breakpoint(smallish) {
  .icon-and-text {
    width: 66.666666%;
    margin-left: 33.333333%;
  }
}


@include breakpoint(medium) {
  .icon-and-text {
    width: 100%;
    margin-left: 0;

    &__wrap {
      display: flex;
      flex-wrap: wrap;
    }
    
    &__item {
      flex: 0 0 50%;
      margin: 20px 0;
    }

    &__icon {
      flex: 0 0 80px;
      height: 80px;
    }

    &__content {
      margin-right: 1rem;
    }
  }
}

@include breakpoint(large) {
  .icon-and-text {
    margin-top: 4rem;
    margin-bottom: 3rem;

    &__item {
      flex: 0 0 calc(33.333333% - 8px);

      &:nth-child(3n + 1) {
        flex: 0 0 calc(33.333333% + 16px);
      }
    }
  }
}